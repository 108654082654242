import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 根据公司id查询部门
 * @param params
 */
export function selectDeptByCompanyId(params) {
    return request({
        url: envInfo.bgApp.organizationPath + `/tenant/dept/list?companyId=${params.companyId}`,
        method: 'get'
    })
}

/**
 * 仓库管理员列表
 * @param params
 */
export function getEmployeeList(params) {
    return request({
        url: envInfo.bgApp.organizationPath + `/tenant/employee/empList?companyId=`+params.companyId,
        method: 'get'
    })
}

/**
 * 查询工单设置
 * @param params
 */
export function getOrderConfig(params) {
    return request({
        url: envInfo.bgApp.orderPath + `/tenant/orderConfig/getConfigDetails?projectId=${params.projectId}`,
        method: 'get'
    })
}

/**
 * 新增工单设置
 * @param params
 */
export function addOrderConfig(params) {
    return request({
        url: envInfo.bgApp.orderPath + `/tenant/orderConfig/addConfig`,
        method: 'post',
        data: params
    })
}

/**
 * 更新工单设置
 * @param params
 */
export function updateOrderConfig(params) {
    return request({
        url: envInfo.bgApp.orderPath + `/tenant/orderConfig/updateConfig`,
        method: 'put',
        data: params
    })
}
