var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container-empty" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { style: { width: "calc(25% - 5px)" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-card",
                    { staticClass: "orderConfigCard" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cardHeader",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                [
                                  _c("title-icon"),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("base.project.list"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.projectList.length > 0
                        ? [
                            _c("div", [
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  class: { ulStyle: _vm.isUl },
                                },
                                _vm._l(_vm.projectList, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.projectId,
                                      staticClass: "projectLabel",
                                      class: { liChosed: index == _vm.current },
                                      attrs: {
                                        title: item.projectName,
                                        label: item.projectName,
                                        value: item.projectId,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showItDetail(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.projectName) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]
                        : [
                            _c("div", { staticClass: "no-data" }, [
                              _vm._v(_vm._s(_vm.$t("commons.noData"))),
                            ]),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { style: { width: "calc(75% - 5px)", marginLeft: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-card",
                    { staticClass: "orderConfigCard" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cardHeader",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("title-icon"),
                          _c("span", [_vm._v(_vm._s(_vm.configTitle))]),
                        ],
                        1
                      ),
                      !_vm.isProjectListNull
                        ? [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "orderConfigForm",
                                    attrs: {
                                      model: _vm.orderConfig,
                                      "label-width": "150px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.declareIfReview"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "hidden",
                                                  attrs: { type: "hidden" },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .orderConfigId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "orderConfigId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.orderConfigId",
                                                  },
                                                }),
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isReportAudit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isReportAudit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isReportAudit",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.declareSuccessDesc"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.approveSequence"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isApproveSequence,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isApproveSequence",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isApproveSequence",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.approveSequenceDesc"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.orderConfig.isReportAudit == "Y"
                                      ? [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.orderReportReview"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 1 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .reportAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "reportAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.reportAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.executeDeptSupervisor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 2 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .reportAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "reportAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.reportAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationPerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 3 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .reportAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "reportAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.reportAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationDept"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.orderConfig
                                                        .reportAuditType == 2
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                key: "reportApproveUser",
                                                                attrs: {
                                                                  multiple: "",
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .reportApproveUserList,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "reportApproveUserList",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.reportApproveUserList",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.empList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.userId,
                                                                      attrs: {
                                                                        label:
                                                                          item.userName,
                                                                        value:
                                                                          item.userId,
                                                                        disabled:
                                                                          item.disabled,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.orderConfig
                                                        .reportAuditType == 3
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                key: "reportDesignatedDept",
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.showTip,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .reportDesignatedDept,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "reportDesignatedDept",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.reportDesignatedDept",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.deptList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.deptId,
                                                                      attrs: {
                                                                        label:
                                                                          item.deptName,
                                                                        value:
                                                                          item.deptId,
                                                                        disabled:
                                                                          item.disabled,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.reportAutoAudit"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("el-switch", {
                                                        attrs: {
                                                          "active-value": "Y",
                                                          "inactive-value": "N",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderConfig
                                                              .isReportTimeout,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderConfig,
                                                              "isReportTimeout",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderConfig.isReportTimeout",
                                                        },
                                                      }),
                                                      _vm.orderConfig
                                                        .isReportTimeout == "Y"
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "order.config.timeoutAudit1"
                                                                  )
                                                                ) +
                                                                "\n                          "
                                                            ),
                                                            _c("el-input", {
                                                              staticClass:
                                                                "input",
                                                              attrs: {
                                                                size: "small",
                                                                maxlength: "3",
                                                                "show-word-limit": true,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .orderConfig
                                                                    .reportTimeoutTime,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderConfig,
                                                                      "reportTimeoutTime",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderConfig.reportTimeoutTime",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "order.config.timeoutAudit2"
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.transferIfReview"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isTransferAudit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isTransferAudit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isTransferAudit",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.transferIfReviewTip"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.orderConfig.isTransferAudit == "Y"
                                      ? [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.orderTransferAudit"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 5 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .transferAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "transferAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.transferAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.recipientAudit"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 1 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .transferAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "transferAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.transferAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.executeDeptSupervisor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 2 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .transferAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "transferAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.transferAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.recipientDeptAudit"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 3 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .transferAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "transferAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.transferAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationPerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 4 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .transferAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "transferAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.transferAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationDept"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.orderConfig
                                                        .transferAuditType == 3
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .transferDesignatedPersonnel,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "transferDesignatedPersonnel",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.transferDesignatedPersonnel",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.empList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.userId,
                                                                      attrs: {
                                                                        label:
                                                                          item.userName,
                                                                        value:
                                                                          item.userId,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.orderConfig
                                                        .transferAuditType == 4
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.showTip,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .transferDesignatedDept,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "transferDesignatedDept",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.transferDesignatedDept",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.deptList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.deptId,
                                                                      attrs: {
                                                                        label:
                                                                          item.deptName,
                                                                        value:
                                                                          item.deptId,
                                                                        disabled:
                                                                          item.disabled,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.orderConfig.transferAuditType != 5
                                            ? [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "order.config.recipientIfReview"
                                                              ),
                                                            },
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-value":
                                                                  "Y",
                                                                "inactive-value":
                                                                  "N",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .orderConfig
                                                                    .isTransferPersonAudit,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderConfig,
                                                                      "isTransferPersonAudit",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderConfig.isTransferPersonAudit",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "order.config.recipientIfReviewTip"
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.assistIfReview"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isAssistAudit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isAssistAudit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isAssistAudit",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.assistIfReviewTip"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.orderConfig.isAssistAudit == "Y"
                                      ? [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.orderAssistAudit"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 5 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .assistAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "assistAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.assistAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.assistPersonAudit"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 1 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .assistAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "assistAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.assistAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.executeDeptSupervisor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 2 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .assistAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "assistAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.assistAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.assistPersonDeptAudit"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 3 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .assistAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "assistAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.assistAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationPerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 4 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .assistAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "assistAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.assistAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationDept"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.orderConfig
                                                        .assistAuditType == 3
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .assistDesignatedPersonnel,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "assistDesignatedPersonnel",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.assistDesignatedPersonnel",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.empList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.userId,
                                                                      attrs: {
                                                                        label:
                                                                          item.userName,
                                                                        value:
                                                                          item.userId,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.orderConfig
                                                        .assistAuditType == 4
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.showTip,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .assistDesignatedDept,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "assistDesignatedDept",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.assistDesignatedDept",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.deptList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.deptId,
                                                                      attrs: {
                                                                        label:
                                                                          item.deptName,
                                                                        value:
                                                                          item.deptId,
                                                                        disabled:
                                                                          item.disabled,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.orderConfig.assistAuditType != 5
                                            ? [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "order.config.assistPersonIfReview"
                                                              ),
                                                            },
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-value":
                                                                  "Y",
                                                                "inactive-value":
                                                                  "N",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .orderConfig
                                                                    .isAssistPersonAudit,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderConfig,
                                                                      "isAssistPersonAudit",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderConfig.isAssistPersonAudit",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "order.config.assistPersonIfReviewTip"
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.delayedIfReview"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isDelayedAudit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isDelayedAudit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isDelayedAudit",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.delayedIfReviewTip"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.orderConfig.isDelayedAudit == "Y"
                                      ? [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.orderDelayedAudit"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 1 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .delayedAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "delayedAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.delayedAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.executeDeptSupervisor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 2 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .delayedAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "delayedAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.delayedAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.declarePerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 3 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .delayedAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "delayedAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.delayedAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationPerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 4 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .delayedAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "delayedAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.delayedAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationDept"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.orderConfig
                                                        .delayedAuditType == 3
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .delayedDesignatedPersonnel,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "delayedDesignatedPersonnel",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.delayedDesignatedPersonnel",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.empList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.userId,
                                                                      attrs: {
                                                                        label:
                                                                          item.userName,
                                                                        value:
                                                                          item.userId,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.orderConfig
                                                        .delayedAuditType == 4
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.showTip,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .delayedDesignatedDept,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "delayedDesignatedDept",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.delayedDesignatedDept",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.deptList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.deptId,
                                                                      attrs: {
                                                                        label:
                                                                          item.deptName,
                                                                        value:
                                                                          item.deptId,
                                                                        disabled:
                                                                          item.disabled,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.materielIfReview"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isMaterielAudit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isMaterielAudit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isMaterielAudit",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.materielIfReviewTip"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.orderConfig.isMaterielAudit == "Y"
                                      ? [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.orderMaterielAudit"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 1 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .materielAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "materielAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.materielAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationPerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 2 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .materielAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "materielAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.materielAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationDept"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.orderConfig
                                                        .materielAuditType == 1
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .materielDesignatedPersonnel,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "materielDesignatedPersonnel",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.materielDesignatedPersonnel",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.empList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.userId,
                                                                      attrs: {
                                                                        label:
                                                                          item.userName,
                                                                        value:
                                                                          item.userId,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.orderConfig
                                                        .materielAuditType == 2
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.showTip,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .materielDesignatedDept,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "materielDesignatedDept",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.materielDesignatedDept",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.deptList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.deptId,
                                                                      attrs: {
                                                                        label:
                                                                          item.deptName,
                                                                        value:
                                                                          item.deptId,
                                                                        disabled:
                                                                          item.disabled,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.completeIfReview"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isCompleteAudit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isCompleteAudit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isCompleteAudit",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.completeIfReviewTip"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.orderConfig.isCompleteAudit == "Y"
                                      ? [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.orderSuccessReview"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 1 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .completeAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "completeAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.completeAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.executeDeptSupervisor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 2 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .completeAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "completeAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.completeAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.declarePerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 3 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .completeAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "completeAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.completeAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationPerson"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: 4 },
                                                          model: {
                                                            value:
                                                              _vm.orderConfig
                                                                .completeAuditType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.orderConfig,
                                                                "completeAuditType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "orderConfig.completeAuditType",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.config.designationDept"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.orderConfig
                                                        .completeAuditType == 3
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                key: "completeApproveUser",
                                                                attrs: {
                                                                  multiple: "",
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .completeApproveUserList,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "completeApproveUserList",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.completeApproveUserList",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.empList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.userId,
                                                                      attrs: {
                                                                        label:
                                                                          item.userName,
                                                                        value:
                                                                          item.userId,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.orderConfig
                                                        .completeAuditType == 4
                                                        ? [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                key: "completeDesignatedDept",
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    _vm.$t(
                                                                      "commons.selectPlease"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.showTip,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .orderConfig
                                                                      .completeDesignatedDept,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.orderConfig,
                                                                        "completeDesignatedDept",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "orderConfig.completeDesignatedDept",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.deptList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.deptId,
                                                                      attrs: {
                                                                        label:
                                                                          item.deptName,
                                                                        value:
                                                                          item.deptId,
                                                                        disabled:
                                                                          item.disabled,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.completeAutoAudit"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("el-switch", {
                                                        attrs: {
                                                          "active-value": "Y",
                                                          "inactive-value": "N",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderConfig
                                                              .isCompleteTimeout,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderConfig,
                                                              "isCompleteTimeout",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderConfig.isCompleteTimeout",
                                                        },
                                                      }),
                                                      _vm.orderConfig
                                                        .isCompleteTimeout ==
                                                      "Y"
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "order.config.timeoutAudit1"
                                                                  )
                                                                ) +
                                                                "\n                          "
                                                            ),
                                                            _c("el-input", {
                                                              staticClass:
                                                                "input",
                                                              attrs: {
                                                                size: "small",
                                                                maxlength: "3",
                                                                "show-word-limit": true,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .orderConfig
                                                                    .completeTimeoutTime,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.orderConfig,
                                                                      "completeTimeoutTime",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "orderConfig.completeTimeoutTime",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "order.config.timeoutAudit2"
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.addDeviceBySelf"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-value": "Y",
                                                    "inactive-value": "N",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .isAddDevice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "isAddDevice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.isAddDevice",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.configTips"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.receiveTopLimit"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "input",
                                                  attrs: {
                                                    size: "small",
                                                    maxlength: "2",
                                                    "show-word-limit": true,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderConfig
                                                        .receiveTopLimit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderConfig,
                                                        "receiveTopLimit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderConfig.receiveTopLimit",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "order.config.receiveTopLimitTips"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.workTimeConfigure"
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    style: {
                                                      marginBottom: "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        style: {
                                                          marginRight: "5px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "order.config.orderExecuteHour"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("el-input", {
                                                      staticClass: "input",
                                                      attrs: {
                                                        size: "small",
                                                        maxlength: "3",
                                                        "show-word-limit": true,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.impWorkingHours,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.impWorkingHours =
                                                            $$v
                                                        },
                                                        expression:
                                                          "impWorkingHours",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        style: {
                                                          marginLeft: "5px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "commons.minute"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-tabs",
                                                  {
                                                    attrs: { type: "card" },
                                                    model: {
                                                      value: _vm.orderLevelTab,
                                                      callback: function ($$v) {
                                                        _vm.orderLevelTab = $$v
                                                      },
                                                      expression:
                                                        "orderLevelTab",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.orderLevelList,
                                                      function (item, index) {
                                                        return [
                                                          _c(
                                                            "el-tab-pane",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.label,
                                                                name:
                                                                  index + "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.waitReceiveConfig1"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "3",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .unclaimedOvertime,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "unclaimedOvertime",
                                                                              _vm._n(
                                                                                $$v
                                                                              )
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .unclaimedOvertime\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.waitReceiveConfig2"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.waitExecuteConfig1"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "3",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .firstExecutedOvertime,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "firstExecutedOvertime",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .firstExecutedOvertime\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.waitExecuteConfig2"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "3",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .secondaryExecutedOvertime,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "secondaryExecutedOvertime",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .secondaryExecutedOvertime\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.waitExecuteConfig3"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.executingConfig1"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "3",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .completedOvertime,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "completedOvertime",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .completedOvertime\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.executingConfig2"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.overtimeConfig1"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "3",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .timeReportDuty,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "timeReportDuty",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .timeReportDuty\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.overtimeConfig2"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.overtimeConfig1"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "3",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .timeReportDept,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "timeReportDept",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .timeReportDept\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.overtimeConfig3"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.overtimeConfig1"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "3",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .timeReportSuperiorDept,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "timeReportSuperiorDept",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .timeReportSuperiorDept\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.overtimeConfig4"
                                                                        )
                                                                      ) +
                                                                      "\n                              "
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        size: "small",
                                                                        maxlength:
                                                                          "1",
                                                                        "show-word-limit": true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orderConfig
                                                                            .configSonList[
                                                                            index
                                                                          ]
                                                                            .timeReportSuperiorMax,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orderConfig
                                                                                .configSonList[
                                                                                index
                                                                              ],
                                                                              "timeReportSuperiorMax",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                                  orderConfig.configSonList[index]\n                                    .timeReportSuperiorMax\n                                ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "order.config.overtimeConfig5"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                    _c("el-tab-pane", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "order.config.orderLevelDesc"
                                                        ),
                                                        disabled: "",
                                                        name: "4",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.defDept"
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder: _vm.$t(
                                                        "commons.selectPlease"
                                                      ),
                                                    },
                                                    on: {
                                                      clear: _vm.clearDefDeptId,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderConfig
                                                          .defDeptId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderConfig,
                                                          "defDeptId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderConfig.defDeptId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.deptList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.deptId,
                                                        attrs: {
                                                          label: item.deptName,
                                                          value: item.deptId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.orderExecuteDept"
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.orderConfig
                                                          .impDeptList,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderConfig,
                                                          "impDeptList",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderConfig.impDeptList",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.deptList,
                                                      function (item) {
                                                        return [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              key: item.deptId,
                                                              attrs: {
                                                                label:
                                                                  item.deptId,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.deptName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "order.config.orderAssistDept"
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.orderConfig
                                                          .assistDeptList,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderConfig,
                                                          "assistDeptList",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderConfig.assistDeptList",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.deptList,
                                                      function (item) {
                                                        return [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              key: item.deptId,
                                                              attrs: {
                                                                label:
                                                                  item.deptId,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.deptName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-form-item",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click:
                                                        _vm.saveOrderConfig,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "order.config.saveConfig"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }