<template>
  <div class="app-container-empty">
    <el-row>
      <!-- 左边项目列表 -->
      <el-col :style="{ width: 'calc(25% - 5px)' }">
        <el-row>
          <el-card class="orderConfigCard">
            <div slot="header" class="cardHeader">
              <el-row>
                <el-col>
                  <title-icon /><span>{{ $t("base.project.list") }}</span>
                </el-col>
              </el-row>
            </div>
            <template v-if="projectList.length > 0">
              <div>
                <ul :class="{ ulStyle: isUl }" v-loading="loading">
                  <li
                    :class="{ liChosed: index == current }"
                    v-for="(item, index) in projectList"
                    class="projectLabel"
                    :title="item.projectName"
                    :label="item.projectName"
                    :value="item.projectId"
                    :key="item.projectId"
                    @click="showItDetail(item, index)"
                  >
                    {{ item.projectName }}
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <div class="no-data">{{ $t("commons.noData") }}</div>
            </template>
          </el-card>
        </el-row>
      </el-col>
      <!-- 右边项目内容 -->
      <el-col :style="{ width: 'calc(75% - 5px)', marginLeft: '10px' }">
        <el-row>
          <el-card class="orderConfigCard">
            <div slot="header" class="cardHeader">
              <title-icon /><span>{{ configTitle }}</span>
            </div>
            <template v-if="!isProjectListNull">
              <div>
                <el-form
                  ref="orderConfigForm"
                  :model="orderConfig"
                  label-width="150px"
                >
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.declareIfReview')">
                        <el-input
                          class="hidden"
                          type="hidden"
                          v-model="orderConfig.orderConfigId"
                        ></el-input>
                        <el-switch
                          v-model="orderConfig.isReportAudit"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.declareSuccessDesc") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.approveSequence')">
                        <el-switch
                          v-model="orderConfig.isApproveSequence"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.approveSequenceDesc") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单报单审核 -->
                  <template v-if="orderConfig.isReportAudit == 'Y'">
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.orderReportReview')"
                        >
                          <!-- //责任部门主管 -->
                          <el-radio
                            v-model="orderConfig.reportAuditType"
                            :label="1"
                            >{{
                              $t("order.config.executeDeptSupervisor")
                            }}</el-radio
                          >
                          <!-- //指定人 -->
                          <el-radio
                            v-model="orderConfig.reportAuditType"
                            :label="2"
                            >{{
                              $t("order.config.designationPerson")
                            }}</el-radio
                          >
                          <!-- //指定部门主管 -->
                          <el-radio
                            v-model="orderConfig.reportAuditType"
                            :label="3"
                            >{{ $t("order.config.designationDept") }}</el-radio
                          >
                          <!-- //指定人的多选框 -->
                          <template v-if="orderConfig.reportAuditType == 2">
                            <el-select
                              v-model="orderConfig.reportApproveUserList"
                              multiple
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              :key="'reportApproveUser'"
                            >
                              <el-option
                                v-for="item in empList"
                                :key="item.userId"
                                :label="item.userName"
                                :value="item.userId"
                                :disabled="item.disabled"
                              >
                              </el-option>
                            </el-select>
                          </template>
                          <!-- //指定部门主管的多选框 -->
                          <template v-if="orderConfig.reportAuditType == 3">
                            <el-select
                              v-model="orderConfig.reportDesignatedDept"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              :key="'reportDesignatedDept'"
                              @change="showTip"
                            >
                              <el-option
                                v-for="item in deptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId"
                                :disabled="item.disabled"
                              >
                              </el-option>
                            </el-select>
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.reportAutoAudit')"
                        >
                          <el-switch
                            v-model="orderConfig.isReportTimeout"
                            active-value="Y"
                            inactive-value="N"
                          ></el-switch>

                          <template v-if="orderConfig.isReportTimeout == 'Y'">
                            {{ $t("order.config.timeoutAudit1") }}
                            <el-input
                              class="input"
                              size="small"
                              maxlength="3"
                              :show-word-limit="true"
                              v-model="orderConfig.reportTimeoutTime"
                            >
                            </el-input>
                            {{ $t("order.config.timeoutAudit2") }}
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('order.config.transferIfReview')"
                      >
                        <el-switch
                          v-model="orderConfig.isTransferAudit"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.transferIfReviewTip") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单转派审核 -->
                  <template v-if="orderConfig.isTransferAudit == 'Y'">
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.orderTransferAudit')"
                        >
                          <el-radio
                            v-model="orderConfig.transferAuditType"
                            :label="5"
                            >{{ $t("order.config.recipientAudit") }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.transferAuditType"
                            :label="1"
                            >{{
                              $t("order.config.executeDeptSupervisor")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.transferAuditType"
                            :label="2"
                            >{{
                              $t("order.config.recipientDeptAudit")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.transferAuditType"
                            :label="3"
                            >{{
                              $t("order.config.designationPerson")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.transferAuditType"
                            :label="4"
                            >{{ $t("order.config.designationDept") }}</el-radio
                          >
                          <template v-if="orderConfig.transferAuditType == 3">
                            <el-select
                              v-model="orderConfig.transferDesignatedPersonnel"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                            >
                              <el-option
                                v-for="item in empList"
                                :key="item.userId"
                                :label="item.userName"
                                :value="item.userId"
                              >
                              </el-option>
                            </el-select>
                          </template>
                          <template v-if="orderConfig.transferAuditType == 4">
                            <el-select
                              v-model="orderConfig.transferDesignatedDept"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              @change="showTip"
                            >
                              <el-option
                                v-for="item in deptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId"
                                :disabled="item.disabled"
                              >
                              </el-option>
                            </el-select>
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <template v-if="orderConfig.transferAuditType != 5">
                      <el-row>
                        <el-col>
                          <el-form-item
                            :label="$t('order.config.recipientIfReview')"
                          >
                            <el-switch
                              v-model="orderConfig.isTransferPersonAudit"
                              active-value="Y"
                              inactive-value="N"
                            ></el-switch>
                            {{ $t("order.config.recipientIfReviewTip") }}
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </template>
                  </template>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.assistIfReview')">
                        <el-switch
                          v-model="orderConfig.isAssistAudit"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.assistIfReviewTip") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单协助审核 -->
                  <template v-if="orderConfig.isAssistAudit == 'Y'">
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.orderAssistAudit')"
                        >
                          <el-radio
                            v-model="orderConfig.assistAuditType"
                            :label="5"
                            >{{
                              $t("order.config.assistPersonAudit")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.assistAuditType"
                            :label="1"
                            >{{
                              $t("order.config.executeDeptSupervisor")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.assistAuditType"
                            :label="2"
                            >{{
                              $t("order.config.assistPersonDeptAudit")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.assistAuditType"
                            :label="3"
                            >{{
                              $t("order.config.designationPerson")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.assistAuditType"
                            :label="4"
                            >{{ $t("order.config.designationDept") }}</el-radio
                          >
                          <template v-if="orderConfig.assistAuditType == 3">
                            <el-select
                              v-model="orderConfig.assistDesignatedPersonnel"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                            >
                              <el-option
                                v-for="item in empList"
                                :key="item.userId"
                                :label="item.userName"
                                :value="item.userId"
                              >
                              </el-option>
                            </el-select>
                          </template>
                          <template v-if="orderConfig.assistAuditType == 4">
                            <el-select
                              v-model="orderConfig.assistDesignatedDept"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              @change="showTip"
                            >
                              <el-option
                                v-for="item in deptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId"
                                :disabled="item.disabled"
                              >
                              </el-option>
                            </el-select>
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <template v-if="orderConfig.assistAuditType != 5">
                      <el-row>
                        <el-col>
                          <el-form-item
                            :label="$t('order.config.assistPersonIfReview')"
                          >
                            <el-switch
                              v-model="orderConfig.isAssistPersonAudit"
                              active-value="Y"
                              inactive-value="N"
                            ></el-switch>
                            {{ $t("order.config.assistPersonIfReviewTip") }}
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </template>
                  </template>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.delayedIfReview')">
                        <el-switch
                          v-model="orderConfig.isDelayedAudit"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.delayedIfReviewTip") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单延时审核 -->
                  <template v-if="orderConfig.isDelayedAudit == 'Y'">
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.orderDelayedAudit')"
                        >
                          <el-radio
                            v-model="orderConfig.delayedAuditType"
                            :label="1"
                            >{{
                              $t("order.config.executeDeptSupervisor")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.delayedAuditType"
                            :label="2"
                            >{{ $t("order.config.declarePerson") }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.delayedAuditType"
                            :label="3"
                            >{{
                              $t("order.config.designationPerson")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.delayedAuditType"
                            :label="4"
                            >{{ $t("order.config.designationDept") }}</el-radio
                          >
                          <template v-if="orderConfig.delayedAuditType == 3">
                            <el-select
                              v-model="orderConfig.delayedDesignatedPersonnel"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                            >
                              <el-option
                                v-for="item in empList"
                                :key="item.userId"
                                :label="item.userName"
                                :value="item.userId"
                              >
                              </el-option>
                            </el-select>
                          </template>
                          <template v-if="orderConfig.delayedAuditType == 4">
                            <el-select
                              v-model="orderConfig.delayedDesignatedDept"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              @change="showTip"
                            >
                              <el-option
                                v-for="item in deptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId"
                                :disabled="item.disabled"
                              >
                              </el-option>
                            </el-select>
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('order.config.materielIfReview')"
                      >
                        <el-switch
                          v-model="orderConfig.isMaterielAudit"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.materielIfReviewTip") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单物料审核 -->
                  <template v-if="orderConfig.isMaterielAudit == 'Y'">
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.orderMaterielAudit')"
                        >
                          <el-radio
                            v-model="orderConfig.materielAuditType"
                            :label="1"
                            >{{
                              $t("order.config.designationPerson")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.materielAuditType"
                            :label="2"
                            >{{ $t("order.config.designationDept") }}</el-radio
                          >
                          <template v-if="orderConfig.materielAuditType == 1">
                            <el-select
                              v-model="orderConfig.materielDesignatedPersonnel"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                            >
                              <el-option
                                v-for="item in empList"
                                :key="item.userId"
                                :label="item.userName"
                                :value="item.userId"
                              >
                              </el-option>
                            </el-select>
                          </template>
                          <template v-if="orderConfig.materielAuditType == 2">
                            <el-select
                              v-model="orderConfig.materielDesignatedDept"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              @change="showTip"
                            >
                              <el-option
                                v-for="item in deptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId"
                                :disabled="item.disabled"
                              >
                              </el-option>
                            </el-select>
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('order.config.completeIfReview')"
                      >
                        <el-switch
                          v-model="orderConfig.isCompleteAudit"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.completeIfReviewTip") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单完成审核 -->
                  <template v-if="orderConfig.isCompleteAudit == 'Y'">
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.orderSuccessReview')"
                        >
                          <el-radio
                            v-model="orderConfig.completeAuditType"
                            :label="1"
                            >{{
                              $t("order.config.executeDeptSupervisor")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.completeAuditType"
                            :label="2"
                            >{{ $t("order.config.declarePerson") }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.completeAuditType"
                            :label="3"
                            >{{
                              $t("order.config.designationPerson")
                            }}</el-radio
                          >
                          <el-radio
                            v-model="orderConfig.completeAuditType"
                            :label="4"
                            >{{ $t("order.config.designationDept") }}</el-radio
                          >
                          <template v-if="orderConfig.completeAuditType == 3">
                            <el-select
                              v-model="orderConfig.completeApproveUserList"
                              multiple
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              :key="'completeApproveUser'"
                            >
                              <el-option
                                v-for="item in empList"
                                :key="item.userId"
                                :label="item.userName"
                                :value="item.userId"
                              >
                              </el-option>
                            </el-select>
                          </template>
                          <template v-if="orderConfig.completeAuditType == 4">
                            <el-select
                              v-model="orderConfig.completeDesignatedDept"
                              clearable
                              :placeholder="$t('commons.selectPlease')"
                              :key="'completeDesignatedDept'"
                              @change="showTip"
                            >
                              <el-option
                                v-for="item in deptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId"
                                :disabled="item.disabled"
                              >
                              </el-option>
                            </el-select>
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col>
                        <el-form-item
                          :label="$t('order.config.completeAutoAudit')"
                        >
                          <el-switch
                            v-model="orderConfig.isCompleteTimeout"
                            active-value="Y"
                            inactive-value="N"
                          ></el-switch>

                          <template v-if="orderConfig.isCompleteTimeout == 'Y'">
                            {{ $t("order.config.timeoutAudit1") }}
                            <el-input
                              class="input"
                              size="small"
                              maxlength="3"
                              :show-word-limit="true"
                              v-model="orderConfig.completeTimeoutTime"
                            >
                            </el-input>
                            {{ $t("order.config.timeoutAudit2") }}
                          </template>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.addDeviceBySelf')">
                        <el-switch
                          v-model="orderConfig.isAddDevice"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        {{ $t("order.config.configTips") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.receiveTopLimit')">
                        <el-input
                          class="input"
                          size="small"
                          maxlength="2"
                          :show-word-limit="true"
                          v-model="orderConfig.receiveTopLimit"
                        />
                        {{ $t("order.config.receiveTopLimitTips") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工时设置 -->
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.workTimeConfigure')">
                        <div :style="{ marginBottom: '10px' }">
                          <span :style="{ marginRight: '5px' }">{{
                            $t("order.config.orderExecuteHour")
                          }}</span>
                          <el-input
                            class="input"
                            size="small"
                            maxlength="3"
                            :show-word-limit="true"
                            v-model="impWorkingHours"
                          >
                          </el-input>
                          <span :style="{ marginLeft: '5px' }">{{
                            $t("commons.minute")
                          }}</span>
                        </div>
                        <el-tabs v-model="orderLevelTab" type="card">
                          <template v-for="(item, index) in orderLevelList">
                            <el-tab-pane
                              :key="index"
                              :label="item.label"
                              :name="index + ''"
                            >
                              <p>
                                {{ $t("order.config.waitReceiveConfig1") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="3"
                                  :show-word-limit="true"
                                  v-model.number="
                                    orderConfig.configSonList[index]
                                      .unclaimedOvertime
                                  "
                                ></el-input>
                                {{ $t("order.config.waitReceiveConfig2") }}
                              </p>
                              <p>
                                {{ $t("order.config.waitExecuteConfig1") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="3"
                                  :show-word-limit="true"
                                  v-model="
                                    orderConfig.configSonList[index]
                                      .firstExecutedOvertime
                                  "
                                ></el-input>
                                {{ $t("order.config.waitExecuteConfig2") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="3"
                                  :show-word-limit="true"
                                  v-model="
                                    orderConfig.configSonList[index]
                                      .secondaryExecutedOvertime
                                  "
                                ></el-input>
                                {{ $t("order.config.waitExecuteConfig3") }}
                              </p>
                              <p>
                                {{ $t("order.config.executingConfig1") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="3"
                                  :show-word-limit="true"
                                  v-model="
                                    orderConfig.configSonList[index]
                                      .completedOvertime
                                  "
                                ></el-input>
                                {{ $t("order.config.executingConfig2") }}
                              </p>
                              <p>
                                {{ $t("order.config.overtimeConfig1") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="3"
                                  :show-word-limit="true"
                                  v-model="
                                    orderConfig.configSonList[index]
                                      .timeReportDuty
                                  "
                                ></el-input>
                                {{ $t("order.config.overtimeConfig2") }}
                              </p>
                              <p>
                                {{ $t("order.config.overtimeConfig1") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="3"
                                  :show-word-limit="true"
                                  v-model="
                                    orderConfig.configSonList[index]
                                      .timeReportDept
                                  "
                                ></el-input>
                                {{ $t("order.config.overtimeConfig3") }}
                              </p>
                              <p>
                                {{ $t("order.config.overtimeConfig1") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="3"
                                  :show-word-limit="true"
                                  v-model="
                                    orderConfig.configSonList[index]
                                      .timeReportSuperiorDept
                                  "
                                ></el-input>
                                {{ $t("order.config.overtimeConfig4") }}
                                <el-input
                                  class="input"
                                  size="small"
                                  maxlength="1"
                                  :show-word-limit="true"
                                  v-model="
                                    orderConfig.configSonList[index]
                                      .timeReportSuperiorMax
                                  "
                                ></el-input>
                                {{ $t("order.config.overtimeConfig5") }}
                              </p>
                            </el-tab-pane>
                          </template>
                          <el-tab-pane
                            :label="$t('order.config.orderLevelDesc')"
                            disabled=""
                            name="4"
                          ></el-tab-pane>
                        </el-tabs>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.defDept')">
                        <el-select
                          v-model="orderConfig.defDeptId"
                          @clear="clearDefDeptId"
                          clearable
                          :placeholder="$t('commons.selectPlease')"
                        >
                          <el-option
                            v-for="item in deptList"
                            :key="item.deptId"
                            :label="item.deptName"
                            :value="item.deptId"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单责任部门 -->
                  <el-row>
                    <el-col>
                      <el-form-item
                        :label="$t('order.config.orderExecuteDept')"
                      >
                        <el-checkbox-group v-model="orderConfig.impDeptList">
                          <template v-for="item in deptList">
                            <el-checkbox
                              :label="item.deptId"
                              :key="item.deptId"
                              >{{ item.deptName }}</el-checkbox
                            >
                          </template>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 工单协助部门 -->
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('order.config.orderAssistDept')">
                        <el-checkbox-group v-model="orderConfig.assistDeptList">
                          <template v-for="item in deptList">
                            <el-checkbox
                              :label="item.deptId"
                              :key="item.deptId"
                              >{{ item.deptName }}</el-checkbox
                            >
                          </template>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item>
                        <el-button type="primary" @click="saveOrderConfig">{{
                          $t("order.config.saveConfig")
                        }}</el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </template>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getEmployeeList,
  selectDeptByCompanyId,
  getOrderConfig,
  updateOrderConfig,
  addOrderConfig,
} from "@/api/business/order/tenant/orderConfig";
import {
  getProjectList,
  getOwnProject,
} from "@/api/business/base/tenant/project";

export default {
  name: "OrderConfig",
  data() {
    return {
      isProjectListNull: true,
      loading: false,
      empList: [],
      deptList: null,
      projectList: [],
      configTitle: null,
      current: 0,
      isTrue: true,
      isSpan: true,
      isUl: true,
      isLi: false,
      orderLevelTab: "0",
      impWorkingHours: null,
      orderLevelList: [
        { level: "0", label: this.$t("order.config.ordinary") },
        { level: "1", label: this.$t("order.config.urgent") },
        { level: "2", label: this.$t("order.config.alarm") },
      ],
      configSon: {
        form: {
          orderType: null,
          orderLevel: null,
          impWorkingHours: null,
          unclaimedOvertime: 0,
          firstExecutedOvertime: 0,
          secondaryExecutedOvertime: 0,
          completedOvertime: 0,
          timeReportDuty: 0,
          timeReportDept: 0,
          timeReportSuperiorDept: 0,
          timeReportSuperiorMax: 0,
        },
      },
      orderConfig: {
        configSonList: [],
        impDeptList: [],
        assistDeptList: [],
        projectId: null,
        orderConfigId: null,

        isApproveSequence: "Y",
        isReportAudit: "N",
        reportAuditType: 1,
        reportApproveUserList: null,
        reportDesignatedDept: null,
        isReportTimeout: null,
        reportTimeoutTime: null,

        isCompleteAudit: "N",
        completeAuditType: 1,
        completeApproveUserList: null,
        completeDesignatedDept: null,
        isCompleteTimeout: null,
        completeTimeoutTime: null,

        isAssistAudit: "N",
        assistAuditType: 5,
        assistDesignatedPersonnel: null,
        assistDesignatedDept: null,
        isAssistPersonAudit: "N",

        isTransferAudit: "N",
        transferAuditType: 5,
        transferDesignatedPersonnel: null,
        transferDesignatedDept: null,
        isTransferPersonAudit: "N",

        isDelayedAudit: "N",
        delayedAuditType: 1,
        delayedDesignatedPersonnel: null,
        delayedDesignatedDept: null,

        isMaterielAudit: "N",
        materielAuditType: 1,
        materielDesignatedPersonnel: null,
        materielDesignatedDept: null,

        isAddDevice: "N",
        receiveTopLimit: null,
        defDeptId: null,
      },
    };
  },
  created() {
    this.getProjects();
    this.initConfigSonList();
  },
  watch: {
    // 切换语言刷新网页Tab
    language: function () {
      this.orderLevelList = [
        { level: "0", label: this.$t("order.config.ordinary") },
        { level: "1", label: this.$t("order.config.urgent") },
        { level: "2", label: this.$t("order.config.alarm") },
      ];
    },
  },
  computed: {
    // 监听语言变化
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    // 初始化状态
    initConfigSonList() {
      this.orderConfig.configSonList = [];
      for (let i = 0; i < 9; i++) {
        let configSon = {
          orderType: null,
          orderLevel: null,
          impWorkingHours: null,
          unclaimedOvertime: 0,
          firstExecutedOvertime: 0,
          secondaryExecutedOvertime: 0,
          completedOvertime: 0,
          timeReportDuty: 0,
          timeReportDept: 0,
          timeReportSuperiorDept: 0,
          timeReportSuperiorMax: 0,
        };
        if (i < 3) {
          configSon.orderType = 1;
          configSon.orderLevel = i;
        } else if (i >= 3 && i < 6) {
          configSon.orderType = 2;
          configSon.orderLevel = i - 3;
        } else if (i >= 6 && i < 9) {
          configSon.orderType = 3;
          configSon.orderLevel = i - 6;
        }
        this.orderConfig.configSonList.push(configSon);
      }
    },

    getProjects() {
      this.loading = true;
      getProjectList()
        .then((res) => {
          this.projectList = res;
          if (this.projectList.length > 0) {
            this.isProjectListNull = false;
          } else {
            this.isProjectListNull = true;
            this.configTitle = this.$t("order.config.pleaseSelectProject");
          }
          this.showItDetail(this.projectList[0], 0);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showItDetail(obj, index) {
      if (obj != undefined) {
        this.getEmployeeList(obj.projectId).then(() => {
          this.configTitle = obj.projectName;
          this.getOrderConfig(obj.projectId);
          this.current = index;
        });
      }
    },
    getEmployeeList(projectId) {
      return getOwnProject(projectId).then((res) => {
        getEmployeeList({ companyId: res.companyId }).then((response) => {
          this.empList = response;
        });
        selectDeptByCompanyId({ companyId: res.companyId }).then((response) => {
          this.deptList = response;
          for (let i = 0, length = this.deptList.length; i < length; i++) {
            if (this.deptList[i].deptHeadId == null) {
              //  alert(123)
              this.deptList[i].disabled = true;
            }
          }
        });
      });
    },

    //指定部门主管-选择选中框
    showTip(data) {
      let deptHeadId;
      for (let i = 0, length = this.deptList.length; i < length; i++) {
        if (this.deptList[i].deptId == data) {
          deptHeadId = this.deptList[i].deptHeadId;
          console.log("打出id2", deptHeadId);
          //假如deptHeadId为null，即不存在负责人
          if (deptHeadId === null) {
            // this.disabled=true,
            this.$message({
              message: this.$t("order.config.deviceTip"),
              type: "info",
            });
          }
        }
      }
    },
    getOrderConfig(projectId) {
      getOrderConfig({ projectId: projectId })
        .then((data) => {
          if (data) {
            let numRe = new RegExp(/^[0-9]*$/);

            this.orderConfig = JSON.parse(JSON.stringify(data));
            console.log("data.reportTimeoutTime", data.reportTimeoutTime);
            if (!this.impWorkingHours || !numRe.test(this.impWorkingHours)) {
              this.impWorkingHours = 0;
            } else {
              this.impWorkingHours =
                this.orderConfig.configSonList[0].impWorkingHours;
            }

            // this.orderConfig.reportTimeoutTime =
            //   data.reportTimeoutTime == null ? 0 : data.reportTimeoutTime;
            // this.orderConfig.completeTimeoutTime =
            //   data.completeTimeoutTime == null ? 0 : data.completeTimeoutTime;

            let assistDeptList = [];
            this.orderConfig.assistDeptList.map((v) => {
              assistDeptList.push(v.deptId);
            });
            this.orderConfig.assistDeptList = assistDeptList;

            let impDeptList = [];
            this.orderConfig.impDeptList.map((v) => {
              impDeptList.push(v.deptId);
            });
            this.orderConfig.impDeptList = impDeptList;

            if (this.orderConfig.reportDesignatedDept == -1) {
              this.orderConfig.reportDesignatedDept = null;
            }

            //协助
            if (this.orderConfig.assistDesignatedPersonnel == -1) {
              this.orderConfig.assistDesignatedPersonnel = null;
            }
            if (this.orderConfig.assistDesignatedDept == -1) {
              this.orderConfig.assistDesignatedDept = null;
            }

            //转派
            if (this.orderConfig.transferDesignatedPersonnel == -1) {
              this.orderConfig.transferDesignatedPersonnel = null;
            }
            if (this.orderConfig.transferDesignatedDept == -1) {
              this.orderConfig.transferDesignatedDept = null;
            }

            //延时
            if (this.orderConfig.delayedDesignatedPersonnel == -1) {
              this.orderConfig.delayedDesignatedPersonnel = null;
            }
            if (this.orderConfig.delayedDesignatedDept == -1) {
              this.orderConfig.delayedDesignatedDept = null;
            }

            //物料
            if (this.orderConfig.materielDesignatedPersonnel == -1) {
              this.orderConfig.materielDesignatedPersonnel = null;
            }
            if (this.orderConfig.materielDesignatedDept == -1) {
              this.orderConfig.materielDesignatedDept = null;
            }

            if (this.orderConfig.completeDesignatedDept == -1) {
              this.orderConfig.completeDesignatedDept = null;
            }
          } else {
            this.clearOrderConfigForm();
          }
          this.orderConfig.projectId = projectId;
          // 处理工时设置的数据
          this.setOrderHour(data.configSonList);
        })
        .catch((err) => {
          // 处理工时设置的数据
          this.setOrderHour(data.configSonList);
        });
    },
    clearOrderConfigForm() {
      this.orderConfig = {
        configSonList: [],
        impDeptList: [],
        assistDeptList: [],
        projectId: null,
        orderConfigId: null,

        isReportAudit: "N",
        reportAuditType: 1,
        reportApproveUserList: null,
        reportDesignatedDept: null,
        isReportTimeout: null,
        reportTimeoutTime: null,

        isCompleteAudit: "N",
        completeAuditType: 1,
        completeApproveUserList: null,
        completeDesignatedDept: null,
        isCompleteTimeout: null,
        completeTimeoutTime: null,

        isAssistAudit: "N",
        assistAuditType: 5,
        assistDesignatedPersonnel: null,
        assistDesignatedDept: null,
        isAssistPersonAudit: "N",

        isTransferAudit: "N",
        transferAuditType: 5,
        transferDesignatedPersonnel: null,
        transferDesignatedDept: null,
        isTransferPersonAudit: "N",

        isDelayedAudit: "N",
        delayedAuditType: 1,
        delayedDesignatedPersonnel: null,
        delayedDesignatedDept: null,

        isMaterielAudit: "N",
        materielAuditType: 1,
        materielDesignatedPersonnel: null,
        materielDesignatedDept: null,

        isAddDevice: "N",
        receiveTopLimit: null,
        defDeptId: null,
      };
      this.initConfigSonList();
    },

    //保存按钮
    saveOrderConfig() {
      //报单
      if (this.orderConfig.isReportAudit == "Y") {
        //报单指定人
        if (this.orderConfig.reportAuditType == 2) {
          if (
            this.orderConfig.reportApproveUserList == null ||
            this.orderConfig.reportApproveUserList.length <= 0
          ) {
            this.$message({
              message:
                this.$t("order.place") +
                this.$t("order.config.personIsNullTip"),
              type: "info",
            });
            return;
          }
        } else {
          this.orderConfig.reportApproveUserList = [];
        }
        //责任部门主管
        if (this.orderConfig.reportAuditType != 3) {
          this.orderConfig.reportDesignatedDept = -1;
        } else {
          //指定部门主管 没有数据的情况下
          if (
            this.orderConfig.reportDesignatedDept == null ||
            this.orderConfig.reportDesignatedDept == -1
          ) {
            this.$message({
              message:
                this.$t("order.place") + this.$t("order.config.deptIsNullTip"),
              type: "info",
            });
            return;
          }
        }
      } else {
        this.orderConfig.reportApproveUserList = [];
        this.orderConfig.reportDesignatedDept = -1;
      }

      //协助
      if (this.orderConfig.isAssistAudit == "Y") {
        if (this.orderConfig.assistAuditType != 3) {
          this.orderConfig.assistDesignatedPersonnel = -1;
        } else {
          if (
            this.orderConfig.assistDesignatedPersonnel == null ||
            this.orderConfig.assistDesignatedPersonnel == -1
          ) {
            this.$message({
              message:
                this.$t("order.assist") +
                this.$t("order.config.personIsNullTip"),
              type: "info",
            });
            return;
          }
        }
        if (this.orderConfig.assistAuditType != 4) {
          this.orderConfig.assistDesignatedDept = -1;
        } else {
          if (
            this.orderConfig.assistDesignatedDept == null ||
            this.orderConfig.assistDesignatedDept == -1
          ) {
            this.$message({
              message:
                this.$t("order.assist") + this.$t("order.config.deptIsNullTip"),
              type: "info",
            });
            return;
          }
        }
      } else {
        this.orderConfig.assistDesignatedPersonnel = -1;
        this.orderConfig.assistDesignatedDept = -1;
      }

      //转派
      if (this.orderConfig.isTransferAudit == "Y") {
        if (this.orderConfig.transferAuditType != 3) {
          this.orderConfig.transferDesignatedPersonnel = -1;
        } else {
          if (
            this.orderConfig.transferDesignatedPersonnel == null ||
            this.orderConfig.transferDesignatedPersonnel == -1
          ) {
            this.$message({
              message:
                this.$t("order.transfer") +
                this.$t("order.config.personIsNullTip"),
              type: "info",
            });
            return;
          }
        }
        if (this.orderConfig.transferAuditType != 4) {
          this.orderConfig.transferDesignatedDept = -1;
        } else {
          if (
            this.orderConfig.transferDesignatedDept == null ||
            this.orderConfig.transferDesignatedDept == -1
          ) {
            this.$message({
              message:
                this.$t("order.transfer") +
                this.$t("order.config.deptIsNullTip"),
              type: "info",
            });
            return;
          }
        }
      } else {
        this.orderConfig.transferDesignatedPersonnel = -1;
        this.orderConfig.transferDesignatedDept = -1;
      }

      //延时
      if (this.orderConfig.isDelayedAudit == "Y") {
        if (this.orderConfig.delayedAuditType != 3) {
          this.orderConfig.delayedDesignatedPersonnel = -1;
        } else {
          if (
            this.orderConfig.delayedDesignatedPersonnel == null ||
            this.orderConfig.delayedDesignatedPersonnel == -1
          ) {
            this.$message({
              message:
                this.$t("order.delayed") +
                this.$t("order.config.personIsNullTip"),
              type: "info",
            });
            return;
          }
        }
        if (this.orderConfig.delayedAuditType != 4) {
          this.orderConfig.delayedDesignatedDept = -1;
        } else {
          if (
            this.orderConfig.delayedDesignatedDept == null ||
            this.orderConfig.delayedDesignatedDept == -1
          ) {
            this.$message({
              message:
                this.$t("order.delayed") +
                this.$t("order.config.deptIsNullTip"),
              type: "info",
            });
            return;
          }
        }
      } else {
        this.orderConfig.delayedDesignatedPersonnel = -1;
        this.orderConfig.delayedDesignatedDept = -1;
      }

      //物料
      if (this.orderConfig.isMaterielAudit == "Y") {
        if (this.orderConfig.materielAuditType != 1) {
          this.orderConfig.materielDesignatedPersonnel = -1;
        } else {
          if (
            this.orderConfig.materielDesignatedPersonnel == null ||
            this.orderConfig.materielDesignatedPersonnel == -1
          ) {
            this.$message({
              message:
                this.$t("order.materiel") +
                this.$t("order.config.personIsNullTip"),
              type: "info",
            });
            return;
          }
        }
        if (this.orderConfig.materielAuditType != 2) {
          this.orderConfig.materielDesignatedDept = -1;
        } else {
          if (
            this.orderConfig.materielDesignatedDept == null ||
            this.orderConfig.materielDesignatedDept == -1
          ) {
            this.$message({
              message:
                this.$t("order.materiel") +
                this.$t("order.config.deptIsNullTip"),
              type: "info",
            });
            return;
          }
        }
      } else {
        this.orderConfig.materielDesignatedPersonnel = -1;
        this.orderConfig.materielDesignatedDept = -1;
      }

      //完成
      if (this.orderConfig.isCompleteAudit == "Y") {
        if (this.orderConfig.completeAuditType == 3) {
          if (
            this.orderConfig.completeApproveUserList == null ||
            this.orderConfig.completeApproveUserList.length <= 0
          ) {
            this.$message({
              message:
                this.$t("order.complete") +
                this.$t("order.config.personIsNullTip"),
              type: "info",
            });
            return;
          }
        } else {
          this.orderConfig.completeApproveUserList = [];
        }
        if (this.orderConfig.completeAuditType != 4) {
          this.orderConfig.completeDesignatedDept = -1;
        } else {
          if (
            this.orderConfig.completeDesignatedDept == null ||
            this.orderConfig.completeDesignatedDept == -1
          ) {
            this.$message({
              message:
                this.$t("order.complete") +
                this.$t("order.config.deptIsNullTip"),
              type: "info",
            });
            return;
          }
        }
      } else {
        this.orderConfig.completeApproveUserList = [];
        this.orderConfig.completeDesignatedDept = -1;
      }

      //当转派审核为接收人时  是否接收人审核设置为否
      if (this.orderConfig.assistAuditType == 5) {
        this.orderConfig.isAssistPersonAudit = "N";
      }
      //当协助审核为协助人时  是否协助人审核设置为否
      if (this.orderConfig.transferAuditType == 5) {
        this.orderConfig.isTransferPersonAudit = "N";
      }

      this.orderConfig.configSonList[0].impWorkingHours = this.impWorkingHours;
      this.orderConfig.configSonList[1].impWorkingHours = this.impWorkingHours;
      this.orderConfig.configSonList[2].impWorkingHours = this.impWorkingHours;

      // 工单工时判断
      this.setOrderHour(this.orderConfig.configSonList);
      let params = this.orderConfig;

      params.impDeptList.forEach((v, index) => {
        if (!v.deptId) {
          //过滤已存在部门转为部门对象（方便参数提交）
          if (this.deptList.findIndex((item) => item.deptId == v) >= 0) {
            params.impDeptList[index] = { deptId: v };
          } else {
            //清除不存在的部门
            params.impDeptList.splice(index, 1);
          }
        }
      });

      params.assistDeptList.forEach((v, index) => {
        if (!v.deptId) {
          //过滤已存在部门转为部门对象（方便参数提交）
          if (this.deptList.findIndex((item) => item.deptId == v) >= 0) {
            params.assistDeptList[index] = { deptId: v };
          } else {
            //清除不存在的部门
            params.assistDeptList.splice(index, 1);
          }
        }
      });

      if (params.orderConfigId) {
        updateOrderConfig(params).then(() => {
          this.getOrderConfig(params.projectId);
          // 成功提示
          this.$message({
            message: this.$t("message.operationSuccess"),
            type: "success",
          });
        });
      } else {
        params.isApproveSequence = params.isApproveSequence || "N";
        addOrderConfig(params).then(() => {
          this.getOrderConfig(params.projectId);
          // 成功提示
          this.$message({
            message: this.$t("message.operationSuccess"),
            type: "success",
          });
        });
      }
    },
    clearDefDeptId() {
      this.orderConfig.defDeptId = null;
    },
    // 处理工时设置的数据
    setOrderHour(data) {
      let configSonList = this.orderConfig.configSonList;
      let numRe = new RegExp(/^[0-9]*$/);

      // 抢单上限
      if (
        !this.orderConfig.receiveTopLimit ||
        !numRe.test(this.orderConfig.receiveTopLimit)
      ) {
        this.orderConfig.receiveTopLimit = 0;
      }
      // 工时设置-工单执行标准工时
      if (!this.impWorkingHours || !numRe.test(this.impWorkingHours)) {
        this.impWorkingHours = 0;
      }
      // 完成自动审核
      if (
        !this.orderConfig.reportTimeoutTime ||
        !numRe.test(this.orderConfig.reportTimeoutTime)
      ) {
        this.orderConfig.reportTimeoutTime = 0;
      }
      // 完成自动审核
      if (
        !this.orderConfig.completeTimeoutTime ||
        !numRe.test(this.orderConfig.completeTimeoutTime)
      ) {
        this.orderConfig.completeTimeoutTime = 0;
      }

      // 工时设置-普通、紧急、告警
      for (let i = 0, length = this.orderLevelList.length; i < length; i++) {
        if (
          !data[i].unclaimedOvertime ||
          !numRe.test(data[i].unclaimedOvertime)
        ) {
          configSonList[i].unclaimedOvertime = 0;
        }
        if (
          !data[i].firstExecutedOvertime ||
          !numRe.test(data[i].firstExecutedOvertime)
        ) {
          configSonList[i].firstExecutedOvertime = 0;
        }
        if (
          !data[i].secondaryExecutedOvertime ||
          !numRe.test(data[i].secondaryExecutedOvertime)
        ) {
          configSonList[i].secondaryExecutedOvertime = 0;
        }
        if (
          !data[i].completedOvertime ||
          !numRe.test(data[i].completedOvertime)
        ) {
          configSonList[i].completedOvertime = 0;
        }
        if (!data[i].timeReportDuty || !numRe.test(data[i].timeReportDuty)) {
          configSonList[i].timeReportDuty = 0;
        }
        if (!data[i].timeReportDept || !numRe.test(data[i].timeReportDept)) {
          configSonList[i].timeReportDept = 0;
        }
        if (
          !data[i].timeReportSuperiorDept ||
          !numRe.test(data[i].timeReportSuperiorDept)
        ) {
          configSonList[i].timeReportSuperiorDept = 0;
        }
        if (
          !data[i].timeReportSuperiorMax ||
          !numRe.test(data[i].timeReportSuperiorMax)
        ) {
          configSonList[i].timeReportSuperiorMax = 0;
        }
      }
    },
  },
};
</script>

<style scoped>
.orderConfigCard >>> .el-card__header {
  padding: 6px 10px;
}
.orderConfigCard >>> .el-card__body {
  height: calc(100vh - 140px);
  overflow: scroll;
}

.cardHeader {
  line-height: 34px;
  font-weight: 600;
}

.orderConfigCard >>> .el-form-item__content {
  padding-left: 20px;
}

.ulStyle {
  padding: 0;
  margin: 0;
}
.ulStyle li {
  list-style: none;
  line-height: 32px;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
}
.liChosed {
  background-color: #8bc1fc21;
  font-weight: 500;
  color: #409eff;
  border-radius: 5px;
}
.input {
  width: 70px;
}
.projectLabel {
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>
